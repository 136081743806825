import axios from "@/plugins/axios";

import { IBasisProjectionParams } from '@/types/service-types';
import { intanciateApiData, isValidResponse } from "@/utilsObject";
import {
  AvailableBasisCities,
  BasisProjectionData,
  BasisMarketProjectionData,
  BasisChartProjectionData
} from "@/business/BasisProjection";

const getBasisProjectionMonthly = async (params: IBasisProjectionParams): Promise<Array<BasisProjectionData>> => {
  const response = await axios.httpApi.get<Array<BasisProjectionData>>("charts/basis-projection", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to get history basis: ${response.statusText}`);
  return response.data;
};


const getBasisProjectionWeekly = async (params: IBasisProjectionParams): Promise<Array<BasisProjectionData>> => {
  const response = await axios.httpApi.get<Array<BasisProjectionData>>("charts/basis-projection", { params });
  if (!isValidResponse(response.status)) {
    console.error(`Error to get basis projection: ${response.statusText}`);
  }
  return response.data;
};


const getBasisMarketProjection = async (params: IBasisProjectionParams): Promise<Array<BasisMarketProjectionData>> => {
  const response = await axios.httpApi.get<Array<BasisMarketProjectionData>>("charts/basis-projection", { params });
  if (!isValidResponse(response.status)) {
    console.error(`Error to get basis projection: ${response.statusText}`);
  }
  return response.data;
};


const getBasisProjectionTodays = async (params: IBasisProjectionParams): Promise<Array<BasisProjectionData>> => {
  const response = await axios.httpApi.get<Array<BasisProjectionData>>("charts/basis-projection", { params });
  if (!isValidResponse(response.status)) {
    console.error(`Error to get basis projection: ${response.statusText}`);
  }
  return response.data;
};


const getBasisProjectionChart = async (params: IBasisProjectionParams): Promise<BasisChartProjectionData> => {
  const response = await axios.httpApi.get<BasisChartProjectionData>("charts/basis-projection", { params });
  if (isValidResponse(response.status)) {
    return intanciateApiData(BasisChartProjectionData, response.data);
  }
  console.error(`Error to get basis projection: ${response.statusText}`);
  return response.data;
};

const getAllBasisCities = async (): Promise<Array<AvailableBasisCities>> => {
  const response = await axios.httpApi.get("charts/all-basis-cities");
  if (!isValidResponse(response.status))
    console.error(`Error to get history basis: ${response.statusText}`);
  return response.data;
};

export const basisProjectionService = {
  getBasisProjectionMonthly,
  getBasisProjectionWeekly,
  getBasisProjectionTodays,
  getBasisMarketProjection,
  getBasisProjectionChart,
  getAllBasisCities
};
