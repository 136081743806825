import { QuotationsDate } from "@/business/Quotations";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import User from "@/models/user";


export default Vue.extend({
  data: () => ({
    shipmentsQuotes: new QuotationsDate(),
    dialog: false as boolean,
    users: [] as Array<User>,
    result: new QuotationsDate(),
  }),

  methods: {
    ...mapActions({
      myOwnerships: "MY_OWNERSHIPS",
      getBasisShipments: "SEARCH_BASIS_SHIPMENT",
      updateBasisShipmentsStore: "UPDATE_BASIS_SHIPMENT",
      deleteBasisShipmentsStore: "DELETE_BASIS_SHIPMENT",
      getUserBasisCities: "SEARCH_USER_ALLOWED_CITY",
      updateUserBasisCitiesStore: "UPDATE_USER_ALLOWED_CITY",
      deleteUserBasisCitiesStore: "DELETE_USER_ALLOWED_CITY",
      deleteUserBasisCities: "DELETE",
    }),
    ...mapMutations(["setResource"]),
    quotationParam(): void {
      //
    },
    basisHistoryLoad(): void {
      //
    },
    socketUpdateQuotes(jsonData: any): void {
      //
    }
  },
  computed: {
    ...mapGetters({
      growings: "getGrowings",
      cities: "getCities",
      user: "getLoggedUser",
      basisShipments: "getBasisShipments",
      userBasisCities: "getUserBasisCities",
    }),
  }
});