import { isEmptyObject, stringToNumber } from "@/utilsObject";

export interface IBasisAsset {
  date?: string;
  week?: number;
  value: number;
}

export class BasisAssetDTO implements IBasisAsset {
  date: string | undefined = undefined;
  week: number | undefined = undefined;
  value = 0;

  constructor(dto?: IBasisAsset) {
    if (dto && !isEmptyObject(dto)) {
      this.date = dto.date;
      this.week = dto.week;
      this.value = stringToNumber(dto.value);
    }
  }
}

export class BasisAsset extends BasisAssetDTO {
  constructor();
  constructor(asset: IBasisAsset);
  constructor(asset?: IBasisAsset) {
    super(asset);
  }
}

export interface IBasisProjectionData {
  city: string;
  growing: string;
  se: string;
  history: Array<BasisAsset>;
}

export class BasisProjectionDataDTO implements IBasisProjectionData {
  city!: string | '';
  growing!: string | '';
  se!: string | '';
  history: Array<BasisAsset> = [];

  constructor(dto?: IBasisProjectionData) {
    if (dto && !isEmptyObject(dto)) {
      this.city = dto.city;
      this.growing = dto.growing;
      this.se = dto.se;
      this.history = dto.history.map((el: BasisAsset) => new BasisAsset(el));
    }
  }
}

export class BasisProjectionData extends BasisProjectionDataDTO {
  constructor();
  constructor(basisProjection: IBasisProjectionData);
  constructor(basisProjection?: IBasisProjectionData) {
    super(basisProjection);
  }
}



export interface IBasiMarketProjectionData {
  se: string;
  se_settle: number;
  dollar_settle: number;
  cash_market: number;
  city: string;
  date: string;
  growing: string;
  state: string;
  week: number;
  code: string;
}

export class BasisMarketProjectionDataDTO implements IBasiMarketProjectionData {
  se!: string | '';
  se_settle!: number | 0;
  dollar_settle!: number | 0;
  cash_market!: number | 0;
  city!: string | '';
  date!: string | '';
  growing!: string | '';
  state!: string | '';
  week!: number | 0;
  code!: string | '';

  constructor(dto?: IBasiMarketProjectionData) {
    if (dto && !isEmptyObject(dto)) {
      this.se = dto.se;
      this.se_settle = dto.se_settle;
      this.dollar_settle = dto.dollar_settle;
      this.cash_market = dto.cash_market;
      this.city = dto.city;
      this.date = dto.date;
      this.growing = dto.growing;
      this.state = dto.state;
      this.week = dto.week;
      this.code = dto?.code;
    }
  }
}

export class BasisMarketProjectionData extends BasisMarketProjectionDataDTO {
  constructor();
  constructor(basisMarketProjection: IBasiMarketProjectionData);
  constructor(basisMarketProjection?: IBasiMarketProjectionData) {
    super(basisMarketProjection);
  }
}

export interface IAvailableBasisCities {
  id: number;
  city: string;
  state: string;
  growing: string;
  growing_symbol: string;
}

export class AvailableBasisCitiesDTO implements IAvailableBasisCities {
  id!: number | 0
  city!: string | '';
  state!: string | '';
  growing!: string | '';
  growing_symbol!: string | '';

  constructor(dto?: IAvailableBasisCities) {
    if (dto && !isEmptyObject(dto)) {
      this.id = dto?.id;
      this.city = dto?.city;
      this.state = dto?.state;
      this.growing = dto?.growing;
      this.growing_symbol = dto?.growing_symbol;
    }
  }
}

export class AvailableBasisCities extends AvailableBasisCitiesDTO {
  constructor();
  constructor(availableCities: IAvailableBasisCities);
  constructor(availableCities?: IAvailableBasisCities) {
    super(availableCities);
  }
}

export interface IBasisChartProjectionData {
  cash_market: Array<BasisMarketProjectionData>;
  five_years: Array<BasisProjectionData>;
}

export class BasisChartProjectionDataDTO implements IBasisChartProjectionData {
  cash_market: Array<BasisMarketProjectionData> = [];
  five_years: Array<BasisProjectionData> = [];

  constructor(dto?: IBasisChartProjectionData) {
    if (dto && !isEmptyObject(dto)) {
      this.cash_market = dto?.cash_market.map((el) => new BasisMarketProjectionData(el));
      this.five_years = dto?.five_years.map((el) => new BasisProjectionData(el));
    }
  }
}

export class BasisChartProjectionData extends BasisChartProjectionDataDTO {
  constructor();
  constructor(basisChartProjectionData: IBasisChartProjectionData);
  constructor(basisChartProjectionData?: IBasisChartProjectionData) {
    super(basisChartProjectionData);
  }
}
